// @ts-nocheck
import axios from 'axios';
import { v1ContractAddress, v1TestContractAddress } from 'common/contracts';
import images from 'common/images';
import contractAbi from 'constants/contractAbi';
import { isInt, timeDifferenceSeige } from 'modules/utils';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  SEIGE_EXPIRE_AT,
  SEIGE_NUMBER,
  SEIGE_TITLE,
  SEIGE_START_AT,
} from 'constants/constants';

const Root = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  overflow: auto;
  justify-content: center;

  display: flex;

  @media (min-width: 768px) {
    align-items: center;
  }
`;

const Container = styled.div`
  position: absolute;
  background-color: #23262f;
  border-radius: 12px;
  width: 450px;
  padding: 25px;

  @media (min-width: 768px) {
    right: 150px;
  }
  @media (max-width: 767px) {
    margin-left: 20px;
    margin-right: 20px;
    top: 150px;
    width: 90%;
  }
`;

const Header = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.1em;
  margin-top: 10px;
  color: #fcfcfd;

  @media (max-width: 600px) {
    font-size: 18px;
  }
`;

const Title = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 38px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.1em;
  margin-top: 25px;
  color: #fcfcfd;
  margin-bottom: 40px;

  @media (max-width: 600px) {
    font-size: 18px;
  }
`;

const Description = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1em;
  margin-bottom: 30px;
  color: #ffffff;
`;

const Background = styled.img`
  width: 100vw;
  height: 100vh;
  position: absolute;
  object-fit: cover;
`;

const Button = styled.img`
  width: 48px;
  height: 48px;
  right: 25px;
  cursor: pointer;
  &:hover {
    border: 2px solid #ffffff;
    border-radius: 24px;
  }
`;

const Time = styled.div`
  background: #393d4c;
  border-radius: 8px;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 41px;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #fcfcfd;
  padding-right: 10px;
  padding-left: 10px;
`;

const DataContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 30px;
`;

const DataRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  padding-right: 10px;
  padding-left: 10px;
`;

const RowTitle = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  font-size: 16px;
`;

const RowDesription = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  font-size: 16px;
`;

const ParticipateButton = styled.div`
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: #05baad;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 4px;
  font-weight: 700;
  font-size: 16px;
  color: white;
  margin-top: 5px;
  font-size: 20px;
  text-decoration: none;
  &:hover {
    background-color: #50f2e7 !important;
  }
`;

const DisabledButton = styled.div`
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: #a9a9a9;
  cursor: not-allowed;
  padding: 10px 20px;
  border-radius: 4px;
  font-weight: 700;
  font-size: 16px;
  color: white;
  margin-top: 5px;
  font-size: 20px;
  text-decoration: none;
  &:hover {
    background-color: #828282 !important;
  }
`;

const Border = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
  border-bottom-width: 1px;
  border-style: solid;
  border-color: #393d4c;
  width: 100%;
`;

const DisclaimerContainer = styled.div`
  margin-bottom: 40px;
`;

const Disclaimer = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 10px;
`;

const Tag = styled.a`
  color: #ffffff;
  text-decoration: none;
  &:hover {
    color: #ffffff;
    text-decoration: none;
  }
`;

const Fortress = () => {
  const [index, setIndex] = React.useState(0);
  const [number, setNumber] = React.useState(0);
  const [reward, setReward] = React.useState(0);
  const [totalKp, setTotalKp] = React.useState(0);
  const [expireAt, setExpireAt] = React.useState(null);
  const [eligibility, setEligibility] = React.useState(false);
  const [myTotal, setMyTotal] = React.useState(0);

  const getMyCryptorus = React.useCallback(async () => {
    const kaikasAddress =
      localStorage.getItem('kaikasAddress') &&
      localStorage.getItem('kaikasAddress').toLowerCase();
    const contract =
      window.caver &&
      new window.caver.klay.Contract(
        contractAbi,
        window.klaytn.networkVersion === 1001
          ? v1TestContractAddress
          : v1ContractAddress,
      );
    const total =
      contract && (await contract.methods.balanceOf(kaikasAddress).call());
    setMyTotal(total);
  }, []);

  React.useEffect(() => {
    getMyCryptorus();
    const getCampData = async () => {
      const kaikasAddress =
        localStorage.getItem('kaikasAddress') &&
        localStorage.getItem('kaikasAddress').toLowerCase();
      const data = {
        attendee: kaikasAddress,
      };
      const response = await axios.post('seige/data', data);
      // console.log('seseas', response.data);
      setNumber(response.data.number);
      setReward(response.data.reward);
      setTotalKp(response.data.totalKp);
      setEligibility(response.data.eligibility);
      setExpireAt(response.data.expireAt);
    };

    getCampData();
  }, [setNumber, setReward, setTotalKp, setEligibility, setExpireAt]);

  return (
    <Root>
      <Background src={images.fortressBg} />
      <Container>
        {index === 0 ? (
          <>
            <Header>- SIEGE BATTLE NO. {`${SEIGE_NUMBER}`} -</Header>
            <Title>{`${SEIGE_TITLE}`}</Title>
            <Description>
              티라노는 알게 모르게 쇠퇴하고 있었다. 프테로와 동맹을 맺어도
              스테고와 브라키 진영을 이기기에는 역부족이였다. 위기감을 느낌
              티라노는 트리키와 함께 할 수 있는 진영을 찾고자 하였지만 쉽지
              않았다.
            </Description>
            <Description>
              브라키 진영은 크게 신경쓰진 않았지만 스테고 진영이 너무 강해져서
              두려움이 더 컸다. 그러나 당분간 스테고 진영과 함께하기로 하였다.
            </Description>
            <Description>
              한편 스테고 진영은 우승이 계속 될 것으로 보이자 프테로 보상을 줄
              생각이 없어보이는데...
            </Description>
            <Button onClick={() => setIndex(1)} src={images.fortressBtn} />
          </>
        ) : (
          <>
            <Header>- SIEGE BATTLE NO. {`${SEIGE_NUMBER}`} -</Header>
            <Title>{`${SEIGE_TITLE}`}</Title>
            {new Date(SEIGE_EXPIRE_AT).getTime() - new Date().getTime() > 0 ? (
              <Time>
                {timeDifferenceSeige(new Date(SEIGE_EXPIRE_AT), new Date())}
              </Time>
            ) : null}

            <DataContainer>
              <DataRow>
                <RowTitle>총 보상</RowTitle>
                {/* <RowDesription>{reward}</RowDesription> */}
                <RowDesription>400 KLAY</RowDesription>
              </DataRow>
              <DataRow>
                <RowTitle>시작일</RowTitle>
                <RowDesription>
                  {new Date(SEIGE_START_AT).toLocaleString()}
                </RowDesription>
              </DataRow>
              <DataRow>
                <RowTitle>종료일</RowTitle>
                <RowDesription>
                  {new Date(SEIGE_EXPIRE_AT).toLocaleString()}
                </RowDesription>
              </DataRow>
              <DataRow>
                <RowTitle>현재 참가 상황</RowTitle>
                <RowDesription>
                  {parseFloat(totalKp.toFixed(4))} KP
                </RowDesription>
              </DataRow>
            </DataContainer>
            <Border />
            <DisclaimerContainer>
              <Disclaimer>
                공성전 참가 후 참가시킨 공룡 NFT를 판매하였을 경우
              </Disclaimer>
              <Disclaimer>보상은 무효 처리될 수 있습니다.</Disclaimer>
            </DisclaimerContainer>

            {myTotal == 0 ? (
              <div>
                <ParticipateButton>
                  <Tag
                    href="https://opensea.io/collection/cryptorus-klay"
                    target="_blank"
                  >
                    크립토러스를 입양해주세요
                  </Tag>
                </ParticipateButton>
              </div>
            ) : new Date(SEIGE_EXPIRE_AT).getTime() - new Date().getTime() <
              0 ? (
              <div>
                <DisabledButton>공성전이 종료되었습니다.</DisabledButton>
              </div>
            ) : myTotal != 0 &&
              eligibility &&
              new Date(SEIGE_START_AT).getTime() - new Date().getTime() > 0 ? (
              <DisabledButton>공성전 시작 전입니다</DisabledButton>
            ) : myTotal != 0 &&
              eligibility &&
              new Date(SEIGE_EXPIRE_AT).getTime() - new Date().getTime() > 0 ? (
              <Link to="/seige" style={{ textDecoration: 'none' }}>
                <ParticipateButton>참가하기</ParticipateButton>
              </Link>
            ) : (
              <div>
                <DisabledButton>이미 참여하셨습니다</DisabledButton>
              </div>
            )}
          </>
        )}
      </Container>
    </Root>
  );
};

export default Fortress;

